import { SVGProps } from 'react';

const Minus = ({
  width = 17,
  height = 2,
  fill = '#FF8200',
  ...otherProps
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 2"
    {...otherProps}>
    <path
      d="M17 -2.98003e-07V1.3L0 1.3L5.68248e-08 -9.53674e-07L17 -2.98003e-07Z"
      fill={fill}
    />
  </svg>
);

export default Minus;
