import { FC, useEffect, useMemo, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import AccountAlt from 'components/Icons/AccountAlt';
import Logo from 'components/Icons/Logo';
import MenuAlt from 'components/Icons/MenuAlt';
import useWindowSize from 'hooks/useWindowSize';
import { HeaderProps } from '../Header';
import { MobileHeaderAnnouncementBanner } from '../HeaderAnnouncementBanner';
import HeaderNavigationBasket from '../HeaderNavigationBasket';
import { RegionKey } from '../HeaderNavigationRegionSelect';
import useHeaderHelpers, {
  MenuActionClickHandler,
  UserMenuAction
} from '../Shared/useHeaderHelpers';
import MobileSidebarOverlay from './MobileSidebarOverlay';

const MobileHeader: FC<HeaderProps> = ({
  showAuthModal,
  isBasketReady,
  basketItemCount,
  toggleBasketOverlay,
  updateRegion
}) => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const {
    menuActionClickHandler: onMenuActionClicked,
    categoryList,
    loggedUser,
    scrollDirection,
    scrollPosY
  } = useHeaderHelpers({
    showAuthModal
  });

  const menuActionClickHandler: MenuActionClickHandler = (
    action: UserMenuAction
  ) => {
    setIsSidebarVisible(false);
    onMenuActionClicked(action);
  };

  const router = useRouter();

  useEffect(() => {
    if (!isSidebarVisible) return;
    setIsSidebarVisible(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  const [isSticky, setIsSticky] = useState(true);

  useEffect(() => {
    if (!window || window.innerWidth >= 1024) return;

    setIsSticky(scrollDirection === 'UP' || scrollPosY === 0);
  }, [scrollPosY, scrollDirection]);

  const { width } = useWindowSize();

  useEffect(() => {
    if (isSidebarVisible) setIsSidebarVisible(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  const onRegionUpdate = (regionKey: RegionKey) => {
    if (regionKey === router.locale) return;

    updateRegion(regionKey);
    setIsSidebarVisible(false);
  };

  return (
    <div data-mobile-navigation className="block lg:hidden">
      <div className="h-[105px]"></div>
      <div
        className={`fixed z-[49] w-full  bg-white transition-all duration-[400ms] ${
          isSticky ? 'top-0' : 'top-[-105px]'
        }`}>
        <MobileHeaderAnnouncementBanner />
        <div className="flex h-[63px] items-center justify-between px-[15px] text-brand-black100">
          <div onClick={() => setIsSidebarVisible(true)} className="flex-1">
            <MenuAlt fill="currentColor" />
          </div>
          <div className="flex flex-auto justify-center">
            <Link prefetch={false} href="/">

              <Logo height={22} width={131} fill="currentColor" />

            </Link>
          </div>
          <div className="flex h-full flex-1 justify-end">
            <div
              className="mr-[22px] flex h-full items-center"
              onClick={() => menuActionClickHandler(UserMenuAction.myProfile)}>
              <AccountAlt fill="currentColor" />
            </div>
            <HeaderNavigationBasket
              className="mx-0 hover:!text-brand-black100"
              ready={isBasketReady}
              itemCount={basketItemCount}
              onBasketClick={() => {
                setIsSidebarVisible(false);
                toggleBasketOverlay();
              }}
            />
          </div>
        </div>
        <MobileSidebarOverlay
          loggedUser={loggedUser}
          menuActionClickHandler={menuActionClickHandler}
          categoryList={categoryList}
          visible={isSidebarVisible}
          updateVisibility={setIsSidebarVisible}
          updateRegion={onRegionUpdate}
        />
      </div>
    </div>
  );
};

export default MobileHeader;
