import { SVGProps } from 'react';

const Expand = ({
  width = 17,
  height = 17,
  fill = '#FF8200',
  ...otherProps
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 17"
    {...otherProps}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.3 0H8V8H0V9.3H8V17H9.3V9.3H17V8H9.3V0Z"
      fill={fill}
    />
  </svg>
);

export default Expand;
