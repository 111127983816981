import { SVGProps } from 'react';

const Marker = ({
  width = 16,
  height = 15,
  fill = '#FF8200',
  ...otherProps
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 15"
    {...otherProps}>
    <path
      d="M9.43269 4.17779C9.42995 3.67819 9.22904 3.19999 8.87419 2.84836C8.51936 2.4966 8.03937 2.3001 7.53977 2.30189C7.04017 2.30368 6.56161 2.50376 6.20928 2.85801C5.85694 3.21227 5.65958 3.69177 5.66053 4.19137C5.66136 4.69109 5.86049 5.1699 6.21415 5.52284C6.56767 5.8759 7.04696 6.07409 7.54656 6.07409C8.04796 6.0723 8.52829 5.87163 8.88184 5.51608C9.23548 5.16052 9.43354 4.67925 9.43272 4.17785L9.43269 4.17779ZM6.34269 4.17779C6.34543 3.8591 6.47437 3.55446 6.70133 3.33072C6.92829 3.10685 7.23473 2.98232 7.55338 2.98409C7.87207 2.98587 8.17705 3.11398 8.40154 3.34023C8.62589 3.5666 8.75138 3.87266 8.75056 4.19134C8.74961 4.51014 8.62244 4.81549 8.39667 5.04056C8.17101 5.26551 7.86531 5.39195 7.54662 5.39195C7.22605 5.39017 6.91937 5.26134 6.69371 5.03365C6.46794 4.80609 6.34171 4.49824 6.34269 4.17779Z"
      fill={fill}
    />
    <path
      d="M1.02853 8.92897L0.00527257 14.5992C-0.0125707 14.6995 0.0153837 14.8025 0.0814035 14.8799C0.147305 14.9575 0.244491 15.0015 0.346315 15H14.7564C14.8582 15.0015 14.9554 14.9575 15.0213 14.8799C15.0873 14.8025 15.1153 14.6995 15.0974 14.5992L14.0742 8.92897C14.0449 8.76422 13.9005 8.64503 13.7331 8.64753H10.1842L11.0727 7.45382C11.7532 6.56334 12.0937 5.45907 12.0327 4.34007C11.8691 1.91162 9.88062 0 7.50357 0C5.12627 0 3.13792 1.91159 2.97409 4.34677C2.91318 5.46602 3.25363 6.57016 3.93416 7.46083L4.82263 8.65453H1.36416C1.20095 8.65382 1.05999 8.76896 1.02824 8.92908L1.02853 8.92897ZM3.65468 4.39096C3.77744 2.57131 5.25093 0.681865 7.50356 0.681865C9.75633 0.681865 11.2297 2.55779 11.3524 4.38761C11.4023 5.34461 11.1089 6.2879 10.5253 7.0479L7.50356 11.0965L4.47653 7.05308C3.89474 6.29238 3.60328 5.34918 3.65456 4.39279L3.65468 4.39096ZM5.33276 9.33643L7.23079 11.8705C7.29515 11.9571 7.39662 12.0081 7.50451 12.0081C7.61228 12.0081 7.71387 11.9571 7.77823 11.8705L9.67449 9.33131H13.4433L14.3436 14.3246H0.748785L1.64914 9.33649L5.33276 9.33643Z"
      fill={fill}
    />
  </svg>
);

export default Marker;
