import { SVGProps } from 'react';

const Logout = ({
  width = 15,
  height = 13,
  fill = '#FF8200',
  ...otherProps
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 13"
    {...otherProps}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.634 12.9999V13H0.101868V0H7.00006H7.634H8.00006V2.5H7.00006V1H1.1073V12H7.00006V10.5H8.00006V12.9999H7.634ZM13.3559 7.99456C14.1642 7.19056 14.8255 6.52178 14.8255 6.50838C14.8255 6.49499 14.1623 5.82441 13.3518 5.01821L11.8782 3.55253L11.0514 4.37493L12.6067 5.92223H5.45315V7.09494H12.6067L11.0516 8.64203L11.4607 9.04925C11.6857 9.27316 11.8735 9.45647 11.8782 9.45647C11.8828 9.45647 12.5477 8.79854 13.3559 7.99456Z"
      fill={fill}
    />
  </svg>
);

export default Logout;
