import { SVGProps } from 'react';

const MenuAlt = ({
  width = 22,
  height = 20,
  fill = '#FF8200',
  ...otherProps
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 20"
    {...otherProps}>
       <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1a1 1 0 0 1 1-1h20a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1Zm0 9a1 1 0 0 1 1-1h11a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1Zm22 9a1 1 0 0 0-1-1H1a1 1 0 1 0 0 2h20a1 1 0 0 0 1-1Z"
      fill={fill}
    />
  </svg>
);

export default MenuAlt;
