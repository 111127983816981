import { FC, useMemo } from 'react';
import AccountAlt from 'components/Icons/AccountAlt';
import BoxAlt from 'components/Icons/BoxAlt';
import Documents from 'components/Icons/Documents';
import Logout from 'components/Icons/Logout';
import Marker from 'components/Icons/Marker';
import { UpdateRegion } from '../HeaderNavigation';
import { MobileRegionSelect } from '../HeaderNavigationRegionSelect';
import {
  MenuActionClickHandler,
  UserMenuAction
} from '../Shared/useHeaderHelpers';
import ReferralIcon from 'components/Icons/ReferralIcon';
import ProductTag from 'components/Others/ProductTag';
interface MobileSidebarUserProps {
  loggedUser?: {
    firstName: string;
  };
  updateRegion: UpdateRegion;
  // eslint-disable-next-line no-unused-vars
  onMenuActionClicked: MenuActionClickHandler;
}
const MobileSidebarUser: FC<MobileSidebarUserProps> = ({
  loggedUser,
  onMenuActionClicked,
  updateRegion
}) => {
  const isLogged = useMemo(() => !!loggedUser?.firstName, [loggedUser]);

  return isLogged ? (
    <LoggedUserNavigation
      firstName={loggedUser?.firstName}
      onMenuActionClicked={onMenuActionClicked}
      updateRegion={updateRegion}
    />
  ) : (
    <NotLoggedUserNavigation
      updateRegion={updateRegion}
      onMenuActionClicked={onMenuActionClicked}
    />
  );
};

const NotLoggedUserNavigation = ({
  onMenuActionClicked,
  updateRegion
}: {
  onMenuActionClicked: MenuActionClickHandler;
  updateRegion: UpdateRegion;
}) => (
  <>
    <div className="px-[16px] text-brand-black100">
      <div className="flex items-center">
        <AccountAlt fill="currentColor" />
        <span className="ml-[16px] text-[18px] leading-[22px]">Hello!</span>
      </div>
      <p className="my-[16px] text-[14px] text-brand-grey300">
        Be the first to hear about new offers, products and events at Dresden.
      </p>
      <div className="grid grid-cols-2 gap-[8px]">
        <button
          onClick={() => onMenuActionClicked(UserMenuAction.signUp)}
          className="h-[40px] rounded-[6px] bg-brand-black100 text-[14px] font-medium leading-[18px] text-white">
          Join Us
        </button>
        <button
          onClick={() => onMenuActionClicked(UserMenuAction.signIn)}
          className="h-[40px] rounded-[6px] border border-brand-grey550 bg-white text-[14px] font-medium leading-[18px]  text-brand-black100">
          Sign In
        </button>
      </div>
      <UserNavigationMenu onMenuActionClicked={onMenuActionClicked} />
    </div>
    <MobileRegionSelect updateRegion={updateRegion} />
  </>
);

const UserNavigationMenu = ({
  onMenuActionClicked
}: {
  onMenuActionClicked: MenuActionClickHandler;
}) => (
  <ul className="my-[20px]">
    <li
      className="mb-[20px] flex text items-center text-brand-grey300"
      onClick={() => onMenuActionClicked(UserMenuAction.myOrders)}>
      <BoxAlt fill="currentColor" />
      <span className="ml-[19px] text-[14px] leading-[20px]">My Orders</span>
    </li>
    <li
      className=" mb-[20px] flex text items-center text-brand-grey300"
      onClick={() => onMenuActionClicked(UserMenuAction.myPrescriptions)}>
      <Documents fill="currentColor" />
      <span className="ml-[20px] text-[14px] leading-[20px]">
        My Prescriptions
      </span>
    </li>
    <li
      className="flex text mb-[20px] items-center text-brand-grey300"
      onClick={() => onMenuActionClicked(UserMenuAction.addresses)}>
      <Marker width={15} fill="currentColor" />
      <span className="ml-[19px] text-[14px] leading-[20px]">My Addresses</span>
    </li>
    <li
      className="flex text mb-[20px] items-center text-brand-grey300"
      onClick={() => onMenuActionClicked(UserMenuAction.referral)}>
      <ReferralIcon width={15} fill="currentColor" />
      <span className="ml-[15px] text-[14px] flex leading-[20px]">Referral Program<span className='ml-2'><ProductTag isSmall={true} text={'New'} isAbsolute={false} textColor="#fff" backgroundColor="#FF8200" /></span></span>
    </li>
    <li
      className="flex text items-center text-brand-grey300"
      onClick={() => onMenuActionClicked(UserMenuAction.myProfile)}>
      <AccountAlt width={15} fill="currentColor" />
      <span className="ml-[19px] text-[14px] leading-[20px]">My Profile</span>
    </li>
  </ul>
);

const LoggedUserNavigation = ({
  firstName,
  onMenuActionClicked,
  updateRegion
}: {
  firstName?: string;
  onMenuActionClicked: MenuActionClickHandler;
  updateRegion: UpdateRegion;
}) => (
  <>
    <div className="px-[16px] text-brand-black100">
      <div className="flex items-center">
        <AccountAlt fill="currentColor" />
        <span className="ml-[16px] text-[18px]">
          Hello <span className="font-medium">{firstName}</span>!
        </span>
      </div>
      <UserNavigationMenu onMenuActionClicked={onMenuActionClicked} />
      <div className="border-t border-brand-grey125 py-[20px]">
        <li
          className=" flex items-center text-brand-grey300"
          onClick={() => onMenuActionClicked(UserMenuAction.logout)}>
          <Logout fill="currentColor" />
          <span className="ml-[19px] text-[14px] leading-[20px]">Log Out</span>
        </li>
      </div>
    </div>
    <MobileRegionSelect updateRegion={updateRegion} />
  </>
);

export default MobileSidebarUser;
